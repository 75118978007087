import React from 'react';

const Card = ({ skill }) => {
  return (
    <div className="card">
      <div className="card__title">
        <h2>{skill}</h2>
      </div>
    </div>
  );
};

export default Card;
