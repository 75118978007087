import React from 'react';
import './header.css';
import HeaderSocials from './HeaderSocials';
import VineetLikhar from '../../assets/Vineet_Likhar.pdf';
import { about } from '../../assets/data';

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <div className="header">
          <div className="header__title">
            <h1>Vineet Likhar</h1>
            <h5>Software Engineer</h5>
            {/* <a href={VineetLikhar} className="btn btn-cv hide" download>
              Resume
            </a> */}
          </div>
          <div className="about">
            <p className="about__me">{about}</p>
          </div>
        </div>
        <HeaderSocials />
        <a href="#skills" className="scroll__down">
          Scroll Down
        </a>
      </div>
    </header>
  );
};

export default Header;
