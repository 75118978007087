import React from 'react';
import { BsLinkedin, BsGithub } from 'react-icons/bs';
import { BiLogoGmail } from 'react-icons/bi';

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="mailto:vineetlikhar1998@gmail.com">
        <BiLogoGmail />
      </a>
      <a
        href="https://www.linkedin.com/in/vineetlikhar"
        target="_blank"
        rel="noreferrer">
        <BsLinkedin />
      </a>
      <a
        href="https://github.com/likharvineet"
        target="_blank"
        rel="noreferrer">
        <BsGithub />
      </a>
    </div>
  );
};

export default HeaderSocials;
