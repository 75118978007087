export const project = [
  {
    title: 'CodeDevil/CD-Library',
    status: 'Completed',
    ongoing: false,
    type: 'Contributions',
    description:
      'Creating npm packages for essential React components, including dropdowns and cards, to optimize frontend development processes and boost productivity.',
    image: 'cd_contribution.png',
    // link: 'https://www.google.com',
    tech: ['HTML', 'SASS', 'TypeScript', 'React', 'Git', 'Figma', 'Storybook'],
  },
  // {
  //   title: 'Car Racing Game with Accelerometer Sensor',
  //   status: 'Ongoing',
  //   ongoing: true,
  //   type: 'Academic',
  //   description:
  //     "Developing an engaging Car Racing game using JavaScript and integrating an Accelerometer sensor to collect real-time acceleration data. Transmitting this data to an AWS EC2 instance to control the in-game car's direction, resulting in an immersive gaming experience.",
  //   image: 'comingsoon.png',
  //   // link: 'https://www.google.com',
  //   tech: [
  //     'HTML',
  //     'CSS',
  //     'JavaScript',
  //     'AWS',
  //     'Git',
  //     'Node JS',
  //     'MongoDB',
  //     'Express JS',
  //   ],
  // },
  // {
  //   title: 'Weather Station Web App',
  //   status: 'Ongoing',
  //   ongoing: true,
  //   type: 'Academic',
  //   description:
  //     'Building a Weather Station application that reads temperature and humidity data from a DHT11 sensor and presents it on a web application, providing users with real-time weather information.',
  //   image: 'comingsoon.png',
  //   // link: 'https://www.google.com',
  //   tech: ['HTML', 'CSS', 'JavaScript'],
  // },
  {
    title: 'Smart Irrigation System',
    status: 'Completed',
    ongoing: false,
    type: 'Academic',
    description:
      'Developed a smart irrigation system using Axios for efficient data fetching, Mongoose for schema creation, HiveMQ MQTT for real-time updates, Docker for containerization, and deployed on AWS EC2 with Nginx, enhancing user interaction by 30% and improving operational efficiency.',
    image: 'smart_irrigation.png',
    // link: 'https://www.google.com',
    tech: ['HTML', 'CSS', 'JavaScript'],
  },
  {
    title: 'Portfolio',
    status: 'Completed',
    ongoing: false,
    type: 'Persoanl',
    description:
      'Building a Weather Station application that reads temperature and humidity data from a DHT11 sensor and presents it on a web application, providing users with real-time weather information.',
    image: 'portfolio.png',
    // link: 'https://www.vineetlikhar.dev',
    tech: ['HTML', 'CSS', 'JavaScript', 'React', 'Git', 'Vercel'],
  },
];

export const skills = [
  {
    label: 'Python',
  },
  {
    label: 'JavaScript',
  },
  {
    label: 'TypeScript',
  },
  {
    label: 'Node.js',
  },
  {
    label: 'React',
  },
  {
    label: 'Redux',
  },
  {
    label: 'Express.js',
  },
  {
    label: 'HTML5',
  },
  {
    label: 'CSS',
  },
  {
    label: 'Jest',
  },
  {
    label: 'Vitest',
  },
  {
    label: 'Git',
  },
  {
    label: 'GitHub',
  },
  {
    label: 'Docker',
  },
  {
    label: 'Jenkins',
  },
  {
    label: 'YAML',
  },
  {
    label: 'MySQL',
  },
  {
    label: 'MongoDB',
  },
  {
    label: 'AWS EC2',
  },
  { label: 'Kubernetes' },
  { label: 'Terraform' },
  { label: 'Ansible' },
  { label: 'Linux' },

];

export const experience = [
  // {
  //   title: 'Software Developer',
  //   company: 'Artech Innovations',
  //   location: 'Nagpur (In)',
  //   date: 'Jan 2022 - Mar 2022',
  //   description: [
  //     'Managed and maintained client dashboards.',
  //     'Implemented new features based on client requirements.',
  //     'Collaborated with industry experts to discern their needs for feature additions.',
  //   ],
  //   techstack: [
  //     'HTML',
  //     'CSS',
  //     'JavaScript',
  //     'JQuery',
  //     'PHP',
  //     'CodeIgniter',
  //     'MySQL',
  //     'Chart.js',
  //   ],
  // },
  {
    title: 'IoT Developer',
    company: 'Shreyvik Industries',
    location: 'Nagpur (In)',
    date: 'Jul 2020 - Oct 2022',
    description: [
      'Suggested and implemented the use of WebSocket protocol over HTTP, enhancing real-time communication efficiency, and revamped frontend with ReactJS, Redux, and Tailwind CSS',
      'Coordinated with the product development team to empower administrators to create user accounts with default passwords and enforced password changes on the first login, increasing security',
      'Integrated cloud storage by facilitating photo uploads to Cloudinary and storing URLs in MySQL, ensuring efficient and reliable media management',
      'Reinforced front-end applications on Vercel using React and improved backend performance with in-house server hosting, resulting in a 10% reduction in server response time',
    ],
    techstack: [
      'Tailwind CSS',
      'Node.js',
      'JavaScript',
      'JQuery',
      'PHP',
      'MySQL',
      'Chart.js',
    ],
  },
];

export const about = `Welcome to my personal website! I am a Computer Engineering enthusiast on a journey of continuous learning and innovation. With a strong background in hardware and software development, I've contributed to a range of projects, from IoT solutions to web applications. As a graduate student at Arizona State University, I'm expanding my horizons in web development.`;
