import React from 'react';
import './skills.css';
import Card from './Card';
import { skills } from '../../assets/data';

const Skills = () => {
  return (
    <section id="skills">
      <div className="container skills__container">
        <h2 className="skills">Tech Stack</h2>
        <div className="skill">
          {skills.map((skill, index) => (
            <Card key={index} skill={skill.label} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
