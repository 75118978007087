import React from 'react';

const Projetcard = ({ project, index }) => {
  return (
    <div className="flip-card">
      <div key={index} className="flip-card-inner">
        <div className="flip-card-front">
          <img
            src={require(`../../assets/${project.image}`)}
            className="project__img"
            alt="project"
          />
          <p>
            <span className={`status ${project.ongoing ? 'yellow' : 'green'}`}>
              <strong> {project.status}</strong>
            </span>{' '}
            <span className="type">
              <strong>{project.type}</strong>
            </span>
          </p>
          <h3>{project.title}</h3>
          <p className="project__card__description">{project.description}</p>
        </div>
        <div className="project__techstack">
          {project.tech.map((tech) => (
            <span key={tech}>{tech}</span>
          ))}
        </div>
      </div>

      {/* <div className="project__card__overlay">
        <div className="project__card-image">
          <img
            src={require(`../../assets/${project.image}.jpeg`)}
            className="project__img"
            alt="project"
          />
        </div>
      </div>
      <article className="details">
        <div className="card__head">
          <h3 className="project__title">{project.title}</h3>
          <p className="project__description">{project.discription}</p>
          <div className="project__techstack">
            {project.tech.map((tech) => (
              <span key={tech}>{tech}</span>
            ))}
          </div>
        </div>
        <a href={project.link} className="btn btn-primary view__project">
          View Project
        </a>
      </article> */}
    </div>
  );
};

export default Projetcard;
