import React from 'react';
import './experience.css';
import { experience } from '../../assets/data';
const Experience = () => {
  return (
    <section id="experience">
      <div className="container experience__container">
        <h2 className="experience__title">Experience</h2>
        {experience.map((experience, index) => (
          <div key={index} className="experience__card">
            <div className="experience-title">
              <h3>{experience.title}</h3>
              <p>{experience.date}</p>
            </div>
            <h4 className="company__name">
              {experience.company},<span>{experience.location}</span>
            </h4>
            <ul className="experience__details">
              {experience.description.map((detail, index) => (
                <li key={index} className="experience__detail__item">
                  {detail}
                </li>
              ))}
            </ul>
            <div className="experience__techstack">
              {experience.techstack.map((tech) => (
                <span key={tech}>{tech}</span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Experience;
