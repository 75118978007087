import React from 'react';
import Nav from './components/nav/Nav';
import Header from './components/header/Header';
import Skills from './components/skills/Skills';
import Experience from './components/experience/Experience';
import Project from './components/projects/Project';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';

const App = () => {
  return (
    <>
      <Nav />
      <Header />
      <Skills />
      <Experience />
      <Project />
      <Contact />
      <Footer />
    </>
  );
};

export default App;
