import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <section className="footer">
      {/* <p>Copyright &copy; 2024. All rights reserved.</p> */}
    </section>
  );
};

export default Footer;
