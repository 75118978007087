import React from 'react';
import './nav.css';
import VineetLikhar from '../../assets/Vineet_Likhar.pdf';

const Nav = () => {
  return (
    <nav className="nav__fill">
      <div className="container nav__container">
        <a href="/#" className="logo">
          VL
        </a>
        <div className="nav">
          <ul className="nav__links">
            <a href="#experience">Experience</a>
            <a href="#projects">Projects</a>
            <a href="#contact">Contact</a>
          </ul>
          {/* <a href={VineetLikhar} className="btn cv" download>
            Resume
          </a> */}
        </div>
      </div>
    </nav>
  );
};

export default Nav;
