import React from 'react';
import './project.css';
import Projectcard from './Projetcard';
import { project } from '../../assets/data';

const Project = () => {
  return (
    <section id="projects">
      <div className="container project__container">
        <h2 className="project__container__heading">Projects</h2>
        <div className="project__card">
          {project.map((project, index) => (
            <Projectcard project={project} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Project;
